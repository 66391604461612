import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={14}
            height={14}
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#prefix__clip0)" fill="currentColor">
                <path d="M13.877 10.868l-2.319-2.545a.23.23 0 00-.39.161v5.097c0 .205.248.306.39.161l2.319-2.55a.226.226 0 000-.324z" />
                <path d="M12.565 4.891c.06.377.415.637.795.574a.691.691 0 00.574-.795c-.52-3.23-3.327-4.515-4.741-4.742a.69.69 0 00-.804.684v8.733a3.833 3.833 0 00-1.736-.399c-1.753 0-3.126 1.07-3.126 2.431 0 1.362 1.373 2.43 3.126 2.43 1.752 0 3.125-1.068 3.125-2.43V1.542c.954.377 2.456 1.29 2.787 3.35zM.75 2.004h5.555a.696.696 0 000-1.39H.75a.696.696 0 000 1.39z" />
                <path d="M.75 4.782h5.555a.696.696 0 000-1.39H.75a.696.696 0 000 1.39zM7 6.865a.696.696 0 00-.695-.694H.75a.696.696 0 000 1.389h5.556A.696.696 0 007 6.865z" />
            </g>
            <defs>
                <clipPath id="prefix__clip0">
                    <path fill="#fff" d="M0 0h14v14H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SvgComponent
