import React, { useEffect, useContext, useState } from 'react'
import { Context } from "../../context";
import Item from '../../components/Recently/item'
import { getRecommendations } from '../../services'


export default function Artists() {

    const { state, dispatch } = useContext(Context);

    const setRecommendations = async () => {
        let data = await getRecommendations();
        dispatch({ type: "getRecommendations", payload: data })
    }

    useEffect(() => {
        setRecommendations();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <section className="bg-white" id="content-wrapper">
            <div className="container-fluid">
                <div className=" revenue__title revenue__title_two d-flex justify-content-between align-items-center">
                    <ul className="top-artist-list">
                        {state.recommendations.data.map((i, k) =>
                            <Item refreshDataset={setRecommendations} items={state.recommendations.data} key={i.id} item={{ ...i, index: k + 1, loading: state.recommendations.loading }} ></Item>
                        )}
                    </ul>
                </div>
            </div >
        </section >
    )
}
