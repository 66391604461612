import React, { useRef } from 'react'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications';
import config from '../../config'

export default function Email() {

    const emailRef = useRef()

    const { addToast } = useToasts()

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await axios.post(`${config.FIREBASE_BASE_URL}/subscribeToMailchimp`, { email_address: emailRef.current.value })
            addToast('Subscribed!', { appearance: "success" })
        } catch (e) {
            let { response: { data: { message } } } = { ...e }
            addToast(message, { appearance: "error" })
        }
    }

    return (
        <section className="mt-5 mb-5" id="content-wrapper">
            <div className="container-fluid">
                <div className="d-flex justify-content-between">
                    <div className="bg-dark w-100 ">
                        <div className="pt-5 pb-5 px-3 email-wrapper">
                            <h1 className="text-white text-center ">We sent monthly reports,<br />
                            right in your inbox.
                            </h1> <p className="text-center mt-2 text-sm">Some cool statistics regarding your music listening habits.</p>
                            <div className="form">
                                <form onSubmit={handleSubmit} id="report">
                                    <input ref={emailRef} required placeholder="Enter your email" type="email"></input>
                                    <input className="monthly-report" value="Get free monthly reports" type="submit"></input>
                                </form>
                            </div>
                            <p className="text-center mt-4 text-xs">No spam, and we never share your data.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
