import React, { useContext } from 'react'
import { Context } from '../../context'
import PropTypes from 'prop-types'
import Play from '../../icons/play'
import { followArtist, unFollowArtist } from '../../services'
import Loader from '../../components/Loader/itemLoader'


function Item({ item, setArtists }) {

    const { state, dispatch } = useContext(Context);

    const handleFollow = async () => {
        if (item.isFollow) {
            await unFollowArtist(item.id);
            setArtists();
        } else {
            await followArtist(item.id)
            setArtists();
        }
    }

    if (item.loading) return <Loader />

    return (
        <li>
            <div className="d-flex figure-wrapper">
                <p className="font-weight-bold">{item.index}</p>
                <Play
                    className="ml-2"
                    onClick={() => {
                        dispatch({ type: "setPlayingTracks", payload: { data: item.tracks.map(i => { return { uri: i.uri, id: i.id } }) } })
                    }}
                    name={[item.uri]} color="#000000" />
            </div>
            <figure className="figure">
                <img alt={item.name} width="50" height="50" src={item.images.length ? item.images[1].url : null}></img>
                <figcaption>
                    <span className="font-weight-bold">{item.name}</span>
                    <span className="figure-subtitle">{item.genres.slice(0, 4).join(', ')}</span>
                </figcaption>
            </figure>
            <div className="switcherWithText col-lg-auto col-12 mb-lg-0 like-wrapper">
                <label onClick={() => handleFollow()} className="switcherWithText_label">{item.isFollow ? 'Following' : 'Follow'}</label>
                <input onChange={() => false} type="checkbox" className="switcherCheckboxText" style={{ display: 'none' }} checked={item.isFollow} />
                <label onClick={() => handleFollow()} className="toggle1">
                    <span />
                </label>
            </div>
        </li>
    )
}


Item.propTypes = {
    isLike: PropTypes.bool
};
export default Item

