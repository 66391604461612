import React from 'react'
import Layout from '../layout'
import queryString from "query-string";
import { getToken } from '../services'
import { Route, Redirect, useHistory } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {

    const history = useHistory();

    return (
        <Route
            {...rest}
            render={() => {
                if (rest.location.search) {
                    let { code } = queryString.parse(rest.location.search)
                    getToken(code, 'client_credentials').then(({ access_token, refresh_token }) => {
                        if (access_token) {
                            localStorage.setItem("token", access_token)
                            localStorage.setItem("refresh_token", refresh_token)
                            if (rest.location.pathname === '/dashboard') history.push('/dashboard')
                        }
                    })

                }
                let token = localStorage.getItem('token');
                return (
                    token !== null ? (
                        <Layout>
                            <Component />
                        </Layout>
                    ) : (
                        <Redirect to={"/login"} />
                    )
                )
            }
            }
        />
    )
};


export default PrivateRoute
