import firebase from "firebase"

const firebaseConfig = {
    apiKey: "AIzaSyDFrDC_w_MyBzGKxv2d8tAdslHuJ3VGeEk",
    authDomain: "echoes-307906.firebaseapp.com",
    projectId: "echoes-307906",
    storageBucket: "echoes-307906.appspot.com",
    messagingSenderId: "245286315902",
    appId: "1:245286315902:web:33b8fabc1f5eaf982eb52f"
}
firebase.initializeApp(firebaseConfig)

const functions = firebase.functions()
export { functions }
export default firebase
