import React from 'react'
import PropTypes from 'prop-types'
import CalendarIcon from '../style/img/icons/calendar.svg'

const CustomSelect = (props) => {
    return (
        <div className="range-picker mr-3">
            <i className="filter-icon filter-iconBlack">
                <img alt="Calendar Icon" src={CalendarIcon}></img>
            </i>
            <select defaultValue={props.defaultValue} ref={props.rangeref} {...props}>
                <option value="long_term">All time</option>
                <option value="medium_term">Last 6 months</option>
                <option value="short_term">Last month</option>
            </select>
        </div>
    )
}

CustomSelect.propTypes = {
    rangeref: PropTypes.object,
    defaultValue: PropTypes.string,
}

export default CustomSelect