import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="43"
            fill="none"
            viewBox="0 0 46 43"
        >
            <path
                stroke="#27C195"
                strokeWidth="1.892"
                d="M44.77 21.5c0 11.283-9.562 20.5-21.445 20.5C11.442 42 1.88 32.782 1.88 21.5 1.88 10.216 11.442 1 23.325 1c11.883 0 21.446 9.216 21.446 20.5z"
            ></path>
            <path
                fill="#27C195"
                d="M32.33 17.442c.886-.665 1.684-1.463 2.305-2.395-.798.355-1.73.621-2.66.71a4.55 4.55 0 002.04-2.572 9.834 9.834 0 01-2.928 1.153 4.657 4.657 0 00-3.415-1.464 4.656 4.656 0 00-4.656 4.657c0 .355.044.71.133 1.064a13.51 13.51 0 01-9.624-4.878c-.4.665-.621 1.463-.621 2.35 0 1.597.798 3.016 2.084 3.859-.753-.045-1.508-.222-2.128-.577v.045a4.663 4.663 0 003.725 4.568c-.355.088-.798.177-1.197.177-.31 0-.577-.044-.887-.089.576 1.863 2.306 3.194 4.346 3.238a9.362 9.362 0 01-5.766 1.996c-.399 0-.753-.045-1.108-.09 2.04 1.331 4.479 2.085 7.14 2.085 8.56 0 13.216-7.051 13.216-13.216v-.62z"
            ></path>
        </svg>
    );
}

export default Icon;
