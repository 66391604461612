import axios from './axios'
import { isSavedTracks } from './index'

const getTracks = async (limit = 50, time_range = "long_term") => {

    try {
        let { data: { items } } = await axios.get('/me/top/tracks', {
            params: {
                limit: limit,
                time_range,
                offset: "0"
            }
        })
        let savedTracks = await isSavedTracks(items.map(i => i.id))
        items = items.map((i, k) => {
            return {
                ...i,
                isSaved: savedTracks[k]
            }
        })
        return {
            data: items,
            error: false,
            loading: false
        }

    } catch (e) {
        let err = { ...e }
        if (err.hasOwnProperty('response')) {
            let { response: { data: { error } } } = err
            return {
                error: true,
                status: error.status,
                message: error.message
            }
        } else {
            return {
                error: true,
                status: "400",
                message: "Something happend with tracks"
            }
        }
    }
}

export default getTracks