import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../style/img/logo-1984.svg'
import Player from '../Player'
import SpotifyPlayer from '../Player/player'


export default function Footer() {
    return (
        <footer id="content-wrapper" className="main bg-dark d-flex justify-content-between main-footer align-items-center main-content">
            <Link to="privacy-policy" className="footer-text">Privacy Policy</Link>
            <div className="align-items-center">
                <a rel="noreferrer" target="_blank" href="https://1984.design/" className="d-flex">
                    <span className="footer-text" >Designed & Developed by</span>
                    <img alt="1984 Logo" className="ml-2" src={Logo}></img>
                </a>
            </div>
            <Player>
                <SpotifyPlayer />
            </Player >
        </footer >
    )
}
