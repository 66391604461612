import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="43"
            fill="none"
            viewBox="0 0 46 43"
        >
            <path
                stroke="#27C195"
                strokeWidth="1.892"
                d="M44.122 21.5c0 11.283-9.562 20.5-21.445 20.5C10.794 42 1.23 32.782 1.23 21.5 1.231 10.216 10.794 1 22.677 1c11.883 0 21.445 9.216 21.445 20.5z"
            ></path>
            <path
                fill="#27C195"
                d="M28.47 28.894c-.56.54-1.712.94-2.815.959h-.124c-3.629 0-4.597-2.77-4.597-4.406v-5.03a.075.075 0 00-.075-.076h-1.755a.075.075 0 01-.075-.075v-2.291c0-.03.019-.058.048-.07 1.875-.73 2.922-2.18 3.198-4.424.015-.124.118-.128.12-.128h2.341c.042 0 .076.034.076.075v4.028c0 .041.033.075.075.075h2.915c.042 0 .076.033.076.075v2.66a.075.075 0 01-.076.076h-2.928a.075.075 0 00-.075.075v4.78c.017 1.076.538 1.622 1.549 1.622.407 0 .873-.095 1.298-.257a.074.074 0 01.098.045l.744 2.208a.076.076 0 01-.018.08zm-3.425 1.686c1.798 0 3.578-.64 4.168-1.415l.118-.156-1.114-3.302a.075.075 0 00-.072-.05h-2.489a.074.074 0 01-.072-.055 1.74 1.74 0 01-.05-.411v-4.039c0-.041.034-.075.076-.075h2.928a.075.075 0 00.075-.075V16.87a.075.075 0 00-.075-.075h-2.916a.075.075 0 01-.075-.075v-4.027a.075.075 0 00-.075-.075H20.36a.869.869 0 00-.85.774c-.255 2.078-1.208 3.326-3 3.924l-.2.067a.075.075 0 00-.051.071v3.548c0 .042.034.075.075.075h1.83v4.37c0 3.488 2.416 5.133 6.882 5.133z"
            ></path>
        </svg>
    );
}

export default Icon;
