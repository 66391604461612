import React, { useContext, useState, useRef } from 'react'
import firebase from '../../services/firebase/index'
import { useToasts } from 'react-toast-notifications';
import axios from 'axios'
import { toBlob, toPng } from 'html-to-image';
import getMonth from '../../utils/getMonth'
import { signInWithSpotify, getTracks, createPlaylist, getRecently, getRecommendations, getArtists, getArtistByIds } from '../../services'
import { NavLink } from 'react-router-dom'
import PlaylistModal from '../PlaylistModal'
import { Context } from "../../context";
import { shuffle } from '../../utils'
import Header from '../Header'
import Modal from '../../components/Modal'
import PlusIcon from '../../icons/plus'
import MusicIcon from '../../icons/music-list'
import Logo from '../../style/img/icons/logo-white.svg'
import DashboardIcon from '../../icons/dashboard'
import AppleIcon from '../../icons/apple'
import config from '../../config'

function Aside() {

    const { addToast } = useToasts()

    const { state: { user, timeRange, ranges }, dispatch } = useContext(Context);

    const [playlistText, setPlaylistText] = useState('')

    const [shareParams, setShareparams] = useState({ title: "", desc: "", link: "" })

    const [showModal, setShowModal] = useState(false)

    const refModal = useRef(null)

    const handleKeyDown = ({ key }) => {
        if (key === 'Escape') setShowTooltip(false)
    }

    const [showTooltip, setShowTooltip] = useState(false);

    const createPlaylistTopTracks = async () => {
        let { data } = await getTracks();
        if (data.length === 0) return false
        let playlist = await createPlaylist(data.map(i => i.uri), `Echoes ✤ Top Tracks ✤ ${ranges[timeRange]} ✤ ${getMonth}`)
        dispatch({ type: "setPlaylist", payload: await addGenreToArtist(playlist) })
        setPlaylistText('top-tracks')
        setShowModal(true)
        generateAndPubishIMG(playlist, 'top-tracks')
    }
    const createPlaylistTopArtists = async () => {
        let { data } = await getArtists(10, "long_term");
        if (data.length === 0) return false
        let shuffled = shuffle(data.map(i => i.tracks).flat())
        let playlist = await createPlaylist(shuffled.map(i => i.uri), `Echoes ✤ Top Artists ✤ ${ranges[timeRange]} ✤ ${getMonth}`)
        dispatch({ type: "setPlaylist", payload: await addGenreToArtist(playlist) })
        setPlaylistText('top-artists')
        setShowModal(true)
        generateAndPubishIMG(playlist, 'top-artists')
    }
    const createPlaylistRecentTracks = async () => {
        let { data } = await getRecently();
        if (data.length === 0) return false
        let playlist = await createPlaylist(data.map(i => i.track.uri), `Echoes ✤ Recently Played Tracks ✤ ${getMonth}`)
        dispatch({ type: "setPlaylist", payload: await addGenreToArtist(playlist) })
        setPlaylistText('recently-played')
        setShowModal(true)
        generateAndPubishIMG(playlist, 'recenty-played')
    }
    const createPlaylistDiscoverTracks = async () => {
        let { data } = await getRecommendations(50, false);
        if (data.length === 0) return false
        let playlist = await createPlaylist(data.map(i => i.uri), `Echoes ✤ New Discovery ✤ ${getMonth}`)
        dispatch({ type: "setPlaylist", payload: await addGenreToArtist(playlist) })
        setPlaylistText('new-discovery')
        setShowModal(true)
        generateAndPubishIMG(playlist, 'new-discovery')
    }

    const generateAndPubishIMG = (playlist, type) => {
        setTimeout(() => {
            toPng(document.getElementById('my-node'), {
                width: 890,
                height: 445,
                style: {
                    opacity: "1",
                }
            })
                .then(async function (blob) {
                    let coverImg = document.getElementById('playlist-cover');
                    coverImg.style.backgroundImage = `url(${blob})`
                    const target = new URL("https://echoesapp.io/playlist/");
                    const tracksString = playlist.data.tracks.items.slice(0, 10).map(i => i.track?.name).join`, `;
                    let artistDesc = `Created a music playlist @echoessapp with My Top Artists @Spotify; ${playlist.data.tracks.items.slice(0, 10).map(i => i.track?.artists[0]?.name).join`, `}`;
                    let recentlyDesc = `Created a music playlist @echoessapp with My Recently Played Tracks @Spotify; ${tracksString} `
                    let discoveryDesc = `Created a music playlist @echoessapp with New Discovery @Spotify; ${tracksString} `
                    let tracksDesc = `Created a music playlist @echoessapp with My Top Tracks @Spotify; ${tracksString} `

                    const metas = {
                        'top-artists': {
                            title: "My Top Artists",
                            desc: artistDesc.length > 250 ? artistDesc.slice(0, 250) + '...' : artistDesc

                        },
                        'recenty-played': {
                            title: "My Recently Played Tracks",
                            desc: recentlyDesc.length > 250 ? recentlyDesc.slice(0, 250) + '...' : recentlyDesc
                        },
                        'new-discovery': {
                            title: "New Discovery",
                            desc: discoveryDesc.length > 250 ? discoveryDesc.slice(0, 250) + '...' : discoveryDesc
                        },
                        'top-tracks': {
                            title: "My Top Tracks",
                            desc: tracksDesc.length > 250 ? tracksDesc.slice(0, 250) + '...' : tracksDesc
                        }
                    }
                    target.searchParams.append("playlistId", playlist.data.id);
                    target.searchParams.append("title", metas[type].title);
                    target.searchParams.append("desc", metas[type].desc);
                    try {
                        let { data } = await axios.post(`${config.FIREBASE_BASE_URL}/generateUrl`,
                            { target: target.href })
                        setShareparams({
                            title: metas[type].title,
                            desc: metas[type].desc,
                            link: data.link.replace('http', 'https'),
                        })
                        toBlob(document.getElementById('my-node'), {
                            width: 790,
                            height: 384,
                            style: {
                                opacity: "1",
                            }
                        }).then(function (blob) {
                            const ref = firebase.storage().ref().child(`covers/${playlist.data.id}.png`);
                            ref.put(blob)
                        });
                    } catch (e) {
                        console.log(e)
                        addToast('Something happend', { appearance: "error" })
                    }
                });
        }, 200);

    }

    const addGenreToArtist = async (playlist) => {
        let refObj = playlist.data.tracks.items;
        let artists = await getArtistByIds(refObj.map(i => i.track.artists[0].id).slice(0, 50))
        refObj.forEach(i => {
            let genre = artists.data.find(k => i.track.artists[0].id === k.id);
            i.track.artists[0].genre = genre?.genres[0]
            return i
        })
        return playlist
    }

    if (user.status === "401") signInWithSpotify()

    return (
        <>
            {showModal ? <Modal setShowModal={setShowModal}>
                <PlaylistModal
                    setShowModal={setShowModal}
                    shareParams={shareParams}
                    playlistText={playlistText} />
            </Modal> : null}
            <aside id="sidebar" className="sidebar-wrapper">
                <div className="sidebar-brand user ">
                    <a href="/dashboard" id="side"><img alt="Echoes Logo" src={Logo}></img></a>
                </div>
                <ul className="sidebar-nav">
                    <li className="parent-menu-active">
                        <a href="/">
                            <i className="menu-icon menu-icon_5"><DashboardIcon />
                            </i>
                            <span>Spotify</span>
                        </a>
                        <ul className="sidebar-submenu">
                            <li><NavLink exact activeClassName="menu-active" to="/dashboard">Dashboard</NavLink></li>
                            <li><NavLink exact activeClassName="menu-active" to="/artists">Top Artists</NavLink></li>
                            <li><NavLink exact activeClassName="menu-active" to="/tracks">Top Tracks</NavLink></li>
                            <li><NavLink exact activeClassName="menu-active" to="/recent">Recently Played</NavLink></li>
                            <li><NavLink exact activeClassName="menu-active" to="/discover">New Discovery</NavLink></li>
                        </ul>
                    </li>
                    <li>
                        <a className="menu-disabled" >
                            <i className="menu-icon menu-icon_5"><AppleIcon />
                            </i>
                            <span>Apple Music</span>
                        </a>
                    </li>
                </ul>
                <nav className="create-playlist-section ml-3">
                    <div className="d-flex align-items-center position-relative">
                        {showTooltip ?
                            <div onMouseLeave={() => setShowTooltip(false)} ref={refModal} onKeyUp={handleKeyDown} tabIndex={0} className="tooltip-playlist card">
                                <div className="card-body">
                                    <h5>Create new playlist</h5>
                                    <h6 className="sub-title">Select tracks to create new Spotify playlists. One at a time, create many.</h6>
                                    <ul>
                                        <li role="button" onClick={() => createPlaylistTopArtists()} ><MusicIcon color="#FF6E6E" />Tracks from top artists</li>
                                        <li role="button" onClick={() => createPlaylistTopTracks()} ><MusicIcon color="#20C933" />Top tracks</li>
                                        <li role="button" onClick={() => createPlaylistRecentTracks()}><MusicIcon color="#FFA800" />Recently Played tracks</li>
                                        <li role="button" onClick={() => createPlaylistDiscoverTracks()}><MusicIcon color="#624CE0" />Discover new tracks</li>
                                    </ul>
                                </div>
                            </div> : null
                        }
                        <div onClick={() => {
                            setShowTooltip(!showTooltip)
                            setTimeout(() => {
                                refModal?.current?.focus()
                            }, 200);
                        }}>
                            <PlusIcon />
                            <span className="ml-3">New Playlist</span>
                        </div>
                    </div>
                </nav>
            </aside >
            <Header user={user.data} />
        </>
    )
}

export default Aside
