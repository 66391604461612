import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                fill="#B2B2B2"
                d="M4.5 15.75H2.25A.75.75 0 011.5 15V9a.75.75 0 01.75-.75H4.5a.75.75 0 01.75.75v6a.75.75 0 01-.75.75zm5.25 0H7.5a.75.75 0 01-.75-.75V2.25a.75.75 0 01.75-.75h2.25a.75.75 0 01.75.75V15a.75.75 0 01-.75.75zm5.25 0h-2.25A.75.75 0 0112 15V6.75a.75.75 0 01.75-.75H15a.75.75 0 01.75.75V15a.75.75 0 01-.75.75z"
            ></path>
        </svg>
    );
}

export default Icon;
