import React, { useEffect, useContext } from "react";
import { Context } from "../context";
import { getTopList } from '../services/'
import Recently from "../components/Recently";
import TopItem from '../components/Top/'

function Dashboard() {

  const { state, dispatch } = useContext(Context);

  const setTopList = async () => {
    let data = await getTopList();
    dispatch({ type: "getTopList", payload: data })
  }

  useEffect(() => {
    setTopList();
  }, [])

  if (state.topList.error) return false

  return <>
    <section className="bg-white" id="content-wrapper">
      <div className="container-fluid">
        <div className="row justify-content-between">
          <TopItem loading={state.topList.loading} items={state.topList.data.artists} data={{ isTrack: false, type: "Artists" }} isTrack={false} />
          <TopItem loading={state.topList.loading} items={state.topList.data.tracks} data={{ isTrack: true, type: "Tracks" }} />
          <TopItem loading={state.topList.loading} items={state.topList.data.recommendations} data={{ isTrack: true, type: "Discover" }} />
        </div>
      </div>
    </section>
    <Recently refreshDataset={setTopList} items={state.topList.data.recently} />
  </>
    ;
}

export default Dashboard;
