import React, { useLayoutEffect } from "react";
import { signInWithSpotify } from '../../services/index'
import Logo from '../../style/img/icons/logo.svg'


function Login() {

  const handleSubmit = (e) => {
    e.preventDefault();
    signInWithSpotify();
  }

  useLayoutEffect(() => {
    document.body.style.backgroundColor = "black"
  }, []);

  return <div className="container body">
    <div className="row login__block">
      <div className="login__block_left col-xl-5 col-lg-5 col-md-12 col-sm-12 ml-auto">
        <img width="152" height="31" className="mr-auto" src={Logo}></img>
        <div className="login__block_title mt-5">
          Discover your music streaming stats
      </div>
        <p className="mt-4">Get insights into your music listening habits with top artists and tracks. Discover new music, create playlists and get monthly reports.</p>
        <div className="login-form">
          <form id="login-form" onSubmit={handleSubmit}>
            <label>Select your service</label>
            <select className="custom-select custom-select_h40">
              <option value="Spotify" selected>Spotify</option>
            </select>
            <input value="Sign-in with Spotify" type="submit" className="sign-up font-weight-bold" form="login-form">
            </input>
          </form>
          <hr />
          <div className="login__account row justify-content-between align-items-center">
            <p className="login-info">Echoes use Spotify Web API, and Spotify Implicit Grant Flow for user Authorization, also require access to your Spotify account to perform but application works as client side only and your Spotify data is not stored to any server. Learn more</p>
          </div>
        </div>
      </div>
      <div className="login__block_right col-xl-5 col-lg-5 col-md-12 col-sm-12 mr-auto">
      </div>
    </div>
  </div>
    ;
}

export default Login;
