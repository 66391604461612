const config = {
  SPOTIFY_AUTH_SCOPES: `user-read-playback-state user-read-currently-playing streaming
    user-read-email
    user-read-private
    user-top-read
    user-follow-modify
    user-library-read
    user-library-modify
    user-follow-read
    user-read-recently-played
    playlist-modify-public
    playlist-modify-private
    playlist-read-private
    playlist-read-collaborative`,
  SPOTIFY_CALLBACK_URL: `${window.location.origin}/dashboard`,
  SPOTIFY_CLIENT_ID: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
  FIREBASE_BASE_URL: process.env.REACT_APP_FIREBASE_BASE_URL,
  SPOTIFY_AUTHORIZE_URL: "https://accounts.spotify.com/authorize",
};

export default config;
