import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
    <ContentLoader
        speed={2}
        width={1220}
        height={400}
        viewBox="0 0 1220 400"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <circle cx="57" cy="49" r="14" />
        <rect x="8" y="37" rx="0" ry="0" width="12" height="22" />
        <rect x="100" y="21" rx="0" ry="0" width="51" height="51" />
        <rect x="167" y="29" rx="5" ry="5" width="209" height="13" />
        <rect x="166" y="50" rx="5" ry="5" width="176" height="13" />
        <rect x="880" y="38" rx="0" ry="0" width="19" height="20" />
        <rect x="935" y="42" rx="5" ry="5" width="28" height="9" />
        <circle cx="57" cy="126" r="14" />
        <rect x="8" y="114" rx="0" ry="0" width="12" height="22" />
        <rect x="100" y="98" rx="0" ry="0" width="51" height="51" />
        <rect x="167" y="106" rx="5" ry="5" width="209" height="13" />
        <rect x="166" y="127" rx="5" ry="5" width="176" height="13" />
        <rect x="880" y="115" rx="0" ry="0" width="19" height="20" />
        <rect x="935" y="119" rx="5" ry="5" width="28" height="9" />
        <circle cx="57" cy="199" r="14" />
        <rect x="8" y="187" rx="0" ry="0" width="12" height="22" />
        <rect x="100" y="171" rx="0" ry="0" width="51" height="51" />
        <rect x="167" y="179" rx="5" ry="5" width="209" height="13" />
        <rect x="166" y="200" rx="5" ry="5" width="176" height="13" />
        <rect x="880" y="188" rx="0" ry="0" width="19" height="20" />
        <rect x="935" y="192" rx="5" ry="5" width="28" height="9" />
        <circle cx="57" cy="271" r="14" />
        <rect x="8" y="259" rx="0" ry="0" width="12" height="22" />
        <rect x="100" y="243" rx="0" ry="0" width="51" height="51" />
        <rect x="167" y="251" rx="5" ry="5" width="209" height="13" />
        <rect x="166" y="272" rx="5" ry="5" width="176" height="13" />
        <rect x="880" y="260" rx="0" ry="0" width="19" height="20" />
        <rect x="935" y="264" rx="5" ry="5" width="28" height="9" />
        <circle cx="58" cy="336" r="14" />
        <rect x="9" y="324" rx="0" ry="0" width="12" height="22" />
        <rect x="101" y="308" rx="0" ry="0" width="51" height="51" />
        <rect x="168" y="316" rx="5" ry="5" width="209" height="13" />
        <rect x="167" y="337" rx="5" ry="5" width="176" height="13" />
        <rect x="880" y="325" rx="0" ry="0" width="19" height="20" />
        <rect x="935" y="329" rx="5" ry="5" width="28" height="9" />
    </ContentLoader>
)

export default MyLoader