import axios from './axios'

const isFollowingArtists = async (artistsId) => {
    try {
        let { data } = await axios.get('/me/following/contains', {
            params: {
                ids: artistsId.join(','),
                type: 'artist'
            }
        })
        return data
    } catch (e) {
        let { response: { data: { error } } } = { ...e }
        return {
            error: true,
            status: error.status,
            message: error.message
        }
    }
}

export default isFollowingArtists