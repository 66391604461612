import React from 'react'
import Volume1 from '../../icons/volume-1'
import Volume2 from '../../icons/volume-2'
import VolumeX from '../../icons/volume-x'

function Volume({ volume }) {
    switch (true) {
        case +volume >= 0.5 && volume <= 1:
            return <Volume2 />
        case +volume <= 0.4 && volume >= 0.1:
            return <Volume1 />
        case +volume === 0:
            return <VolumeX />
        default:
    }
}

export default Volume
