import React, { useEffect, useContext } from 'react'
import { Context } from "../../context";
import { getArtists } from '../../services'
import Item from './item'

export default function Artists() {

    const { state: { timeRange, artists }, dispatch } = useContext(Context);

    const setArtists = async () => {
        let data = await getArtists(50, timeRange);
        dispatch({ type: "getArtists", payload: data })
    }

    useEffect(() => {
        setArtists();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <section className="bg-light-gray" id="content-wrapper">
                <div className="container-fluid">
                    <div className=" revenue__title revenue__title_two d-flex justify-content-between align-items-center">
                        <ul className="top-artist-list">
                            {artists.data.map((i, k) =>
                                <Item key={k} setArtists={setArtists} item={{ ...i, index: k + 1, loading: artists.loading }} />)}
                        </ul>
                    </div>
                </div >
            </section >
        </>
    )
}
