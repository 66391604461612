import React, { createContext, useReducer } from "react";
import reduce from './reducers'

export const Context = createContext();

const initialState = {
    user: {
        data: {
            display_name: null,
            email: null,
            images: [{
                url: null
            }]
        },
        loading: true,
        error: false,
        status: '',
        message: ''
    },
    topList: {
        data: {
            artists: [{ images: [{ url: null }, { url: null }, { url: null }] }],
            tracks: [{
                album: { images: [{ url: null }, { url: null }, { url: null }] }
            }],
            recommendations: [{
                album: { images: [{ url: null }, { url: null }, { url: null }] }
            }],
            recently: [{
                track: { duration_ms: 0, album: { artists: [{ name: null }], images: [{ url: null }, { url: null }, { url: null }] } }
            }],
        },
        loading: true,
        error: false,
        status: '',
        message: ''
    },
    artists: {
        data: [{ images: [{ url: null }, { url: null }, { url: null }] }],
        loading: true,
        error: false,
        status: '',
        message: ''
    },
    tracks: {
        data: [{
            album: { images: [{ url: null }, { url: null }, { url: null }] }
        }],
        loading: true,
        error: false,
        status: '',
        message: ''
    },
    recommendations: {
        data: [{
            album: { images: [{ url: null }, { url: null }, { url: null }] }
        }],
        loading: true,
        error: false,
        status: '',
        message: ''
    },
    recently: {
        data: [{
            track: { duration_ms: 0, album: { artists: [{ name: null }], images: [{ url: null }, { url: null }, { url: null }] } }
        }],
        loading: true,
        error: false,
        status: '',
        message: ''
    },
    playingTracks: {
        source: "",
        data: [],
        isSaved: []
    },
    currentlyPlaying: {
        data: { paused: false },
        loading: true,
        isSaved: false,
        isSetted: false,
    },
    playerPlaying: false,
    player: null,
    playlist: {},
    deviceId: "",
    timeRange: "long_term",
    ranges: {
        long_term: "All Time",
        medium_term: "Last 6 months",
        short_term: "Last month"
    },
    error: {
        message: ""
    }
}

const ContextProvider = ({ children }) => {

    const reducer = (state, action) => {
        if (action.payload.error) {
            return {
                ...state,
                error: action.payload
            }
        }
        switch (action.type) {
            case reduce.GET_ARTISTS:
                return {
                    ...state,
                    artists: action.payload
                };
            case reduce.SET_PLAYINGTRACKS:
                return {
                    ...state,
                    playingTracks: {
                        ...state.playingTracks,
                        data: action.payload.data,
                    }
                };
            case reduce.SET_SAVEDTRACKS:
                return {
                    ...state,
                    playingTracks: {
                        ...state.playingTracks,
                        isSaved: action.payload
                    }
                };
            case reduce.SET_CURRENTLYPLAYING:
                return {
                    ...state,
                    currentlyPlaying: {
                        ...state.currentlyPlaying,
                        data: action.payload.data,
                        loading: action.payload.loading,
                    }
                }
            case reduce.GET_RECENTLY:
                return {
                    ...state,
                    recently: action.payload
                };
            case reduce.GET_RECOMMENDATIONS:
                return {
                    ...state,
                    recommendations: action.payload
                };
            case reduce.GET_TOPLIST:
                return {
                    ...state,
                    topList: action.payload
                };
            case reduce.GET_TRACKS:
                return {
                    ...state,
                    tracks: action.payload
                };
            case reduce.GET_USER:
                return {
                    ...state,
                    user: action.payload
                };
            case reduce.SET_PLAYER:
                return {
                    ...state,
                    player: action.payload
                };
            case reduce.SET_PLAYLIST:
                return {
                    ...state,
                    playlist: action.payload
                };

            case reduce.SET_DEVICE:
                return {
                    ...state,
                    deviceId: action.payload
                };
            case reduce.SET_COLLAPSE:
                return {
                    ...state,
                    collapsed: action.payload
                };
            case reduce.SET_TIMERANGE:
                return {
                    ...state,
                    timeRange: action.payload
                };
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Context.Provider value={{ state, dispatch }} >
            {children}
        </Context.Provider>
    );
};
export default ContextProvider;