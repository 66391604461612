import React, { useContext, useEffect } from 'react'
import { Context } from '../../context'
import { getTracks } from '../../services';
import Item from '../../components/Recently/item'

export default function Tracks() {

    const { state: { timeRange, tracks }, dispatch } = useContext(Context);

    const setTracks = async () => {
        let data = await getTracks(50, timeRange);
        dispatch({ type: "getTracks", payload: data })
    }

    useEffect(() => {
        setTracks();
    }, [])

    return (
        <section className="bg-light-gray" id="content-wrapper">
            <div className="container-fluid">
                <div className=" revenue__title revenue__title_two d-flex justify-content-between align-items-center">
                    <ul className="top-artist-list">
                        {tracks.data.map((i, k) =>
                            <Item items={tracks.data} refreshDataset={setTracks} key={k} item={{ ...i, index: k + 1, loading: tracks.loading }} />)}
                    </ul>
                </div>
            </div >
        </section >
    )
}
