import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width="2rem"
      height="2rem"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 14.857h-.016a1.143 1.143 0 100 2.286h16.031a1.143 1.143 0 100-2.286H8z"
        fill="#fff"
      />
      <path
        d="M17.143 8v-.016a1.143 1.143 0 10-2.286 0V8 8v16.015a1.143 1.143 0 102.286 0V8z"
        fill="#fff"
      />
      <path
        d="M16 0C7.177 0 .001 7.177.001 15.999c0 8.822 7.177 15.999 15.999 15.999 8.823 0 15.999-7.177 15.999-15.999C31.999 7.177 24.822 0 16 0zm0 2.286A13.697 13.697 0 0129.714 16 13.697 13.697 0 0116 29.714 13.696 13.696 0 012.286 16 13.696 13.696 0 0116 2.286z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent
