import React from "react";

function Icon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="13"
            fill="none"
            viewBox="0 0 14 13"
        >
            <g fill="#fff" opacity="0.6">
                <path d="M11.848 6.5L1.152.325v12.35L11.848 6.5z"></path>
                <path d="M12 1H14V12H12z"></path>
            </g>
        </svg>
    );
}

export default Icon;
